
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IAgency, IGeoLocation, LabelValue } from "@/types";
import clonedeep from "lodash.clonedeep";
import ContactForm from "@/components/ContactForm.vue";
import NumbersForm from "@/components/NumbersForm.vue";
import PicturePreview from "@/components/PicturePreview.vue";
import InputGmapAutocomplete from "@/components/form/inputGmapAutocomplete.vue";
import ListAutocompleteSelect from "@/components/ListAutocompleteSelect.vue";
import { Getter } from "vuex-class";
import PreviewAgency from "@/components/modal/PreviewAgency.vue";
import AgencyRead from "@/components/agency/AgencyRead.vue";
import InlineField from "@/components/InlineField.vue";
import Badge from '@/dsComponents/Badge/Badge.vue';
import { formattedAddress } from "@/helpers/commons";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
    name: "AgencyForm",
    components: {
        InlineField,
        AgencyRead,
        PreviewAgency,
        ListAutocompleteSelect,
        PicturePreview,
        NumbersForm,
        ContactForm,
        InputGmapAutocomplete,
        LMap,
        LTileLayer,
        LMarker,
        Badge
    }
})
export default class AgencyForm extends Vue {
    $refs!: {
        agencyName: any
    };

    agency: IAgency = {
        name: "",
        siret: "",
        email: "",
        website: "",
        address: {
            street: '',
            city: '',
            zip: ''
        },
        socialNetworks: {
            linkedin: '',
            twitter: '',
            facebook: '',
            instagram: ''
        },
        logo: '',
        video: '',
        description: '',
        numbers: [],
        delegationDepartments: []
    };

    edit:any = {
        agencyName: false
    };

    mode = "info";

    showPreviewAgency = false;

    center:IGeoLocation = {lat: 48.845617, lng: 2.2431133};

    url:string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'

    attribution:string = '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'

    @Prop() value!: IAgency;
    @Prop() errors!: any;
    @Prop() globalErrors!: any;
    @Prop(Boolean) centeredPicture!: boolean;
    @Prop(Boolean) readonly!: boolean;

    @Getter('jobOptions') jobOptions!: LabelValue[];
    @Getter('businessSectorOptions') businessSectorOptions!: LabelValue[];
    @Getter('isAgency') isAgency!: LabelValue[];

    @Watch('agency', {deep: true})
    onClient() {
        this.$emit('input', this.agency);
    }

    get zoom() {
        return (this.mapMarkerPosition && 16) || 8;
    }

    get starterAgency() {
        return this.agency.subscriptionType === 'starter';
    }

    mapMarkerPosition: any = null;

    get agencyAddress() {
        return formattedAddress(this.agency.address);
    }


    get delegationDepartments() {
        console.log('get');
        return this.agency.delegationDepartments?.toString() || "";
    }

    set delegationDepartments(v:string) {
        let deps: string[] = v.replace(/ /gmi, '').split(',');
        const checked:any = {};

        deps = deps.filter((d:string) => {
            if (!checked[d]) {
                checked[d] = true;
            } else {
                return false;
            }
            return true;
        });
        console.log(deps);
        this.$set(this.agency, 'delegationDepartments', deps);
        this.$forceUpdate();
    }

    editAgencyName() {
        this.edit.agencyName = true
        this.$nextTick(() => {
            this.$refs.agencyName.$el.querySelector('input').focus();
        });
    }

    stopEditAgencyName() {
        this.edit.agencyName = false;
    }

    placeChanged(place: any) {
        if (!this.agency.address) {
            this.agency.address = {street:'', city:'', zip: ''};
        }
        if (this.agency.address) {
            this.agency.address.name = "main";
            this.agency.address.street = place.street;
            this.agency.address.complement = place.complement;
            this.agency.address.city = place.locality;
            this.agency.address.zip = place.postal_code;
            this.agency.address.countryCode = place.countryCode;
            this.agency.address.location = [place.longitude, place.latitude];
            this.mapMarkerPosition = {lat: place.latitude, lng: place.longitude};
        }
    }

    beforeMount() {
        if (this.value) {
            this.agency = clonedeep(this.value);

            if (!this.agency.socialNetworks) {
                this.agency.socialNetworks = {
                    linkedin: '',
                    twitter: '',
                    facebook: '',
                    instagram: ''
                }
            }
            if (!this.agency.survey) {
                this.agency.survey = {
                    mainBusinessSectors: [],
                    developingBussinessSectors: [],
                    jobsRequiringMissions: [],
                    jobsRequiringWorkers: [],
                    clientSoftwares: "",
                    dematerializationSoftwares: ""
                }
            }
            if (this.agency.address?.location?.length) {
                this.mapMarkerPosition = {lat: this.agency.address.location[1], lng: this.agency.address.location[0]};
            }
        }
    }
}
