
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";
    import {IAgencyDB} from "@/types";
    import AgencyForm from "@/components/agency/AgencyForm.vue";
    import AgencyRead from "@/components/agency/AgencyRead.vue";

    @Component({
        name: "PreviewAgency",
        components: {AgencyRead, AgencyForm}
    })
    export default class PreviewAgency extends mixins(ModalMixin) {
        name = "PreviewAgency";

        @Prop() agency!: IAgencyDB;
    }
